/* eslint-disable react/prop-types */
import React from 'react';

const ProgressBar = (props) => {
  const { completed } = props;

  const containerStyles = {
    height: 30,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 50,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: 'var(--primary-background-color)',
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s ease-in-out',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed.toFixed(0)}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
