import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import calcTotalEstimate from '../../../../functions/pricing/calcEstimate';
import PRICING_ACTIONS from '../../../../redux/actions/Pricing';
import ProgressBar from '../../../ProgressBar';
import currencyFormatter from 'currency-formatter';

const QuestionBar = ({ index, count }) => {
  const [completed, setCompleted] = React.useState(0);
  const { answers } = useSelector((state) => state.Pricing);
  const dispatch=useDispatch();

  React.useEffect(() => {
    const action = PRICING_ACTIONS.setEstimate(
      currencyFormatter.format(calcTotalEstimate(answers), { code: 'USD' })
    );
    dispatch(action);
  }, []);

  React.useEffect(() => {
    if (index === 1) {
      setCompleted(33);
    } else {
      setCompleted(33 + (index / count) * 66);
    }
  }, [index, count]);
  return (
    <div className='container' style={{ width: '50%' }}>
      <ProgressBar completed={completed} />
    </div>
  );
};

export default QuestionBar;
