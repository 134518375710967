import React from 'react';
import { useLocation } from '@reach/router';
import Head from '../../components/Head/Head';
import Header from '../../components/Header/Header';
import PlatformsQuestion from '../../layouts/pricing/question/platforms';
import useClidParser from '../../hooks/pricing/useClidParser';

const platforms = () => {
  const location = useLocation();
  useClidParser();

  return (
    <>
      <Head />
      <Header pageId={location.pathname} hideLinks />
      <div className="mt-10">

        <PlatformsQuestion />
      </div>
    </>
  );
};

export default platforms;
