import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./styles.module.css";

const QuestionText = ({ index, heading, children, customClass }) => (
  <div
    className={clsx("container", styles.container, customClass && customClass)}
  >
    <h2 className="section__h2">
      {index ?? null}{!!index &&<>.</> }{"  "}
      {heading}
    </h2>
    <div>{children}</div>
  </div>
);

QuestionText.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
  customClass: PropTypes.string,
};

export default QuestionText;
