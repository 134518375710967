import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QuestionText from '../../../../components/pricing/question/questionText';
import classConcat from '../../../../util/ClassConcat';
import QUESTIONS from '../../../../constants/pricing/questions';
import styles from './styles.module.css';
import PRICING_ACTIONS from '../../../../redux/actions/Pricing';
import PlatformOptions from './PlatformOptions';
import QuestionBar from '../../../../components/pricing/question/questionBar';

const PlatformsQuestion = () => {
  // Hooks
  const dispatch = useDispatch();

  const Pricing = useSelector((state) => state.Pricing);
  const { answers } = Pricing;
  const platforms = typeof answers.get('platforms') === 'object'
    ? answers.get('platforms')
    : {};

  const questionIndex = 0;
  const question = QUESTIONS[questionIndex];

  const answersComp = {
    web: false,
    ios: false,
    android: false,
    ...platforms,
  };

  // Events
  const handleChange = (data) => {
    const setAnswer = PRICING_ACTIONS.setAnswer(question.id, data);
    dispatch(setAnswer);
  };
  return (
    <>
      <QuestionBar index={0 + 1} count={QUESTIONS.length} />
      <div className={classConcat('container', styles.container)}>
        <h4 className="section__h1">What You Get: 5 Stage Split</h4>
        <p className="section__p">
          See your app's cost, time & sample documents in 5 stages by selecting a few options for your app.
        </p>
        <p className="section__p">
          Here is what you can expect at the end of each stage:
          <ol>
            <li>
              <b>A Clear Plan for Success</b>
              {' '}
              and an Overview of Screens.
            </li>
            <li>
              <b>Pencil/Rough Sketches</b>
              {' '}
              of screens and
              {' '}
              <b>plan for tech</b>
              {' '}
              to use.
            </li>
            <li>
              <b>Detailed Design</b>
              {' '}
              with Color, Logos, Sample Images and
              {' '}
              <b>details of tech</b>
              {' '}
              to use.
              {' '}
            </li>
            <li>
              A
              {' '}
              <b>Usable App</b>
              {' '}
              ready for internal testing.
            </li>
            <li>
              A
              {' '}
              <b>Public App</b>
              {' '}
              ready for Users.
            </li>
          </ol>
        </p>
        <p className="section__p">
          Picking your app's options takes 1 minute.
        </p>
        <p className="section__p">
          This estimate is based on 100 Great Apps built Fast.
        </p>

        <h4 className="section__h1">Publishing your app</h4>
        <p className="section__p">
          Publishing your app takes work.
          <br />
          This work depends on the locations your app needs to be published in.
        </p>
      </div>
      <QuestionText
        index={questionIndex + 1}
        heading="Where will the app be published?"
      >
        <p className="section__p">
          Please pick at least one platform to proceed.
        </p>
      </QuestionText>

      <PlatformOptions
        answers={answersComp}
        setAnswers={handleChange}
        platforms={[
          {
            text: 'iOS on Apple App Store',
            questionId: question.id,
            answerId: question.answerIds.IOS,
          },
          {
            text: 'Android on Google Play Store',
            questionId: question.id,
            answerId: question.answerIds.ANDROID,
          },
          {
            text: 'Web App or PWA',
            questionId: question.id,
            answerId: question.answerIds.WEB,
          },
        ]}
      />
    </>
  );
};
export default PlatformsQuestion;
